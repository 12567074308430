<template>
  <!-- Plan -->
  <section aria-labelledby="team_members">
    <div class="shadow rounded-md overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h2
            id="team_members"
            class="text-lg leading-6 font-semibold text-gray-900"
          >
            General Settings
          </h2>
        </div>
        <div class="flex flex-row space-x-10">
          <div class="flex-1 flex flex-col">
            <div class="flex flex-col space-y-1">
              <label class="text-gray-800 font-semibold" for="addEmail"
                >Team Name</label
              >
              <input
                type="text"
                id="addEmail"
                name="addEmail"
                class="w-full h-10 px-2 py-1.5 bg-white rounded-lg border-gray-200"
                placeholder="CloudSprouter"
              />
            </div>
          </div>

          <!-- <div class="flex content-center items-center justify-center"> -->
          <div
            class="w-40 h-40 relative rounded-full overflow-hidden lg:block transition duration-150 ease-in-out bg-gray-300 shadow-lg"
          >
            <div
              class="w-full h-full flex items-center content-center justify-center text-xl"
              v-if="profileImage == null"
            >
              <i class="fa-duotone fa-buildings text-gray-700 text-5xl"></i>
            </div>
            <img
              class="relative rounded-full w-full h-full"
              :src="profileImage.publicImageUrl"
              v-if="
                profileImage !== null && profileImage.publicImageUrl !== null
              "
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center text-sm leading-5 font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100 transition duration-150 ease-in-out cursor-pointer"
              v-on:click="profilePictureModalOpen = !profilePictureModalOpen"
            >
              <span>Change</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="Change Team Profile Picture"
      :open="profilePictureModalOpen"
      @close="profilePictureModalOpen = !profilePictureModalOpen"
      :showAcceptButton="false"
    >
      <p class="text-gray-600">
        Select a team picture here.
      </p>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4"
        v-if="profileImages.length !== 0"
      >
        <div
          class="bg-gray-50 rounded-lg overflow-hidden shadow-md relative cursor-pointer"
          v-for="image in profileImages"
          v-bind:key="image.id"
          v-on:click="updateProfileImage(image)"
          :class="{
            'ring-2 ring-blue-500':
              profileImage !== null && image.id == profileImage.id,
          }"
        >
          <div
            class="absolute top-0 right-0 w-6 h-6 rounded-full bg-blue-600 text-white flex items-center content-center justify-center mt-2 mr-2 text-sm"
            v-if="profileImage !== null && image.id == profileImage.id"
          >
            <i class="fa-solid fa-check mt-0.5"></i>
          </div>
          <img class="w-full h-auto" :src="image.publicImageUrl" />
        </div>
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Modal from "@/components/Modal";

export default {
  name: "GeneralSettings",
  data() {
    return {
      showUploadPopover: false,
      profilePictureModalOpen: false,
      profileImages: [],
    };
  },
  components: { Modal },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      profileImage: (state) => state.profileImage,
    }),
  },
  watch: {
    profilePictureModalOpen: function() {
      this.getStuff();
    },
  },
  methods: {
    ...mapActions("team", ["updateTeamContentSection"]),
    async getStuff() {
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.currentTeamId +
          "/settings/profile_images"
      );
      this.profileImages = res.data;
    },
    async updateProfileImage(image) {
      await this.updateTeamContentSection({ type: "profileImage", val: image });
    },
  },
};
</script>
