<template>
  <!-- Watermark -->
  <section aria-labelledby="team_members">
    <div class="shadow rounded-md overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h2
            id="team_members"
            class="text-lg leading-6 font-semibold text-gray-900"
          >
            Watermark Settings
          </h2>
        </div>
        <div class="w-full space-y-4">
          <p>
            Watermark profiles are used to apply a consistent watermark across
            all of your content. Within a watermark profile, you can upload a
            image, set the opacity, and specify the location of the watermark.
          </p>
          <button
            class="px-4 py-1.5 bg-gray-700 text-white rounded-lg hover:bg-gray-500 focus:outline-none"
            v-on:click="
              modalStatus.addWatermarkOpen = !modalStatus.addWatermarkOpen
            "
          >
            Create a Profile
          </button>
        </div>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
          v-if="watermarks.length !== 0"
        >
          <div
            class="bg-gray-50 p-2 rounded-lg overflow-hidden shadow-md"
            v-for="(watermark, index) in watermarks"
            v-bind:key="watermark.id"
          >
            <div
              class="flex flex-row items-center content-center justify-between rounded"
            >
              <div class="flex flex-row space-x-2">
                <div
                  class="inline-flex justify-center items-center w-6 h-6 bg-blue-600 text-white rounded-full"
                  v-if="defaultWatermarkId == watermark.id"
                >
                  <i class="fa-solid fa-star text-xs fa-fw"></i>
                </div>
                <h2 class="font-semibold">
                  {{
                    watermark.name == null || watermark.name.length == 0
                      ? "Untitled"
                      : watermark.name
                  }}
                </h2>
              </div>

              <div
                class="w-8 h-8 bg-gray-200 text-gray-800 flex items-center justify-center content-center rounded-full cursor-pointer hover:bg-red-500 transition hover:text-white"
                v-on:click="deleteWatermark(watermark, index)"
              >
                <i class="fa-solid fa-trash-can text-sm"></i>
              </div>
            </div>
            <div
              class="bg-checkered mt-2 rounded-lg flex items-center content-center justify-center overflow-hidden group relative select-none"
            >
              <img
                class="object-contain w-full h-40 p-6"
                :style="{ opacity: watermark.opacity / 100 }"
                :src="watermark.publicImageUrl"
              />
              <div
                class="absolute opacity-0 group-hover:opacity-100 transition h-full w-full text-white flex flex-col space-y-2 items-center content-center justify-center cursor-pointer"
                style="background-color: rgba(0,0,0,0.6)"
                v-on:click="editWatermark(watermark, index)"
              >
                <i class="fa-solid fa-pen-circle text-5xl"></i>
                <span>Edit Watermark</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="Add Watermark"
      :open="modalStatus.addWatermarkOpen"
      @close="modalStatus.addWatermarkOpen = !modalStatus.addWatermarkOpen"
      :showAcceptButton="false"
      size="large"
    >
      <div class="w-full flex flex-col lg:space-y-4 rounded-lg">
        <div class="w-full space-y-4">
          <div class="flex flex-col space-y-1">
            <label class="text-gray-800 font-semibold" for="profile"
              >Profile Name</label
            >
            <input
              type="text"
              id="profile"
              name="profile"
              class="w-full h-10 px-2 py-1.5 bg-white rounded-lg border-gray-200"
              placeholder="Default"
              v-model="profileName"
            />
          </div>
          <div class="flex flex-col space-y-1">
            <label class="font-semibold">Watermark File</label>
            <label
              class="items-center content-center py-2 px-4 bg-white rounded-md tracking-wide border border-blue cursor-pointer space-x-3 inline flex-none"
              @change="uploadFileChanged"
            >
              <i class="fa-solid fa-upload"></i>
              <span class="text-base leading-normal">{{
                uploadFileName !== null ? uploadFileName : "Select a file"
              }}</span>
              <input type="file" class="hidden" accept="image/*" />
            </label>
          </div>
          <div class="flex flex-col space-y-1">
            <label class="font-semibold">Watermark Preview</label>
            <div class="w-full relative" ref="watermarkContainer">
              <div
                class="aspect-ratio-16/9 bg-checkered rounded relative overflow-hidden"
              >
                <div class="absolute w-full h-full">
                  <!-- <div class=""></div> -->
                  <img
                    ref="watermarkPreview"
                    v-if="imageData"
                    class="absolute cursor-move"
                    :src="imageData"
                    :style="{
                      width: this.fileComputedSize.width + 'px',
                      height: 'auto',
                      opacity: this.opacity + '%',
                    }"
                    @mousedown="watermarkPreviewDragMouseDown"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col sm:flex-row justify-between space-y-4 sm:space-x-4 sm:space-y-0"
          >
            <div
              class="flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0"
            >
              <div class="flex flex-col">
                <label>Watermark Scale Factor</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  step="1"
                  v-model="wmSf"
                  class="w-48"
                />
              </div>
              <div class="flex flex-col">
                <label>Watermark Opacity</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  v-model="opacity"
                  class="w-48"
                />
              </div>
            </div>
            <button
              class="px-4 py-1.5 bg-gray-700 text-white rounded-lg hover:bg-gray-500 focus:outline-none"
              v-on:click="submitWatermarkProfile"
            >
              Save Profile
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      title="Edit Watermark"
      :open="modalStatus.editWatermarkOpen"
      @close="modalStatus.editWatermarkOpen = !modalStatus.editWatermarkOpen"
      :showAcceptButton="false"
      size="large"
    >
      <div class="w-full flex flex-col lg:space-y-4 rounded-lg">
        <div class="w-full space-y-4">
          <div class="flex flex-col space-y-1">
            <label class="text-gray-800 font-semibold" for="profile"
              >Profile Name</label
            >
            <input
              type="text"
              id="profile"
              name="profile"
              class="w-full h-10 px-2 py-1.5 bg-white rounded-lg border-gray-200"
              placeholder="Default"
              v-model="profileName"
            />
          </div>
          <div class="flex flex-col space-y-1">
            <label class="font-semibold">Watermark Preview</label>
            <div class="w-full relative" ref="watermarkContainerEdit">
              <div
                class="aspect-ratio-16/9 bg-checkered rounded relative overflow-hidden"
              >
                <div class="absolute w-full h-full">
                  <!-- <div class=""></div> -->
                  <img
                    ref="watermarkPreviewEdit"
                    class="absolute cursor-move"
                    :src="editData.image"
                    :style="{
                      width: this.fileComputedSize.width + 'px',
                      height: this.fileComputedSize.height + 'px',
                      opacity: this.opacity + '%',
                    }"
                    @mousedown="watermarkPreviewDragMouseDownEdit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col sm:flex-row justify-between space-y-4 sm:space-x-4 sm:space-y-0"
          >
            <div
              class="flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0"
            >
              <div class="flex flex-col">
                <label>Watermark Scale Factor</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  step="1"
                  v-model="wmSf"
                  class="w-48"
                />
              </div>
              <div class="flex flex-col">
                <label>Watermark Opacity</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  v-model="opacity"
                  class="w-48"
                />
              </div>
            </div>
            <button
              class="px-4 py-1.5 bg-gray-700 text-white rounded-lg hover:bg-gray-500 focus:outline-none"
              v-on:click="updateWatermark"
            >
              Save Profile
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";

import Modal from "@/components/Modal";

export default {
  name: "WatermarkSettings",
  components: { Modal },
  data() {
    return {
      showUploadPopover: false,
      placementTypes: [
        "Cover",
        "Pin Top-Left",
        "Pin Top-Right",
        "Pin Bottom-Left",
        "Pin Bottom-Right",
      ],
      showCreateWatermarkProfile: false,
      uploadFileName: null,
      fileInformation: {
        width: null,
        height: null,
      },
      imageData: null,
      imagePositions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        clickX: null,
        clickY: null,
      },
      wmSelected: false,
      fileComputedSize: {
        width: null,
      },
      wmSf: 100,
      opacity: 100,
      selectedFile: null,
      profileName: null,
      imgTop: null,
      imgLeft: null,
      modalStatus: {
        addWatermarkOpen: false,
        editWatermarkOpen: false,
        editWatermarkOpenReady: false,
      },
      editData: {
        image: null,
      },
      watermarkIndex: null,
    };
  },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      watermarks: (state) => state.watermarks,
      defaultWatermarkId: (state) => state.defaultWatermarkId,
    }),
  },
  methods: {
    uploadFileChanged(event) {
      var fileData = event.target.files[0];

      this.selectedFile = fileData;
      this.uploadFileName = fileData.name;

      var reader = new FileReader();
      var self = this;
      reader.readAsDataURL(fileData);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        self.imageData = e.target.result;
        image.onload = function() {
          self.fileInformation.width = this.width;
          self.fileInformation.height = this.height;
        };
      };
    },
    watermarkPreviewDragMouseDown(event) {
      console.log(event);

      event.preventDefault();
      this.imagePositions.clientX = event.clientX;
      this.imagePositions.clientY = event.clientY;

      this.imagePositions.clickX = event.offsetX;
      this.imagePositions.clickY = event.offsetY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
      this.wmSelected = true;
    },
    watermarkPreviewDragMouseDownEdit(event) {
      console.log(event);

      event.preventDefault();
      this.imagePositions.clientX = event.clientX;
      this.imagePositions.clientY = event.clientY;

      this.imagePositions.clickX = event.offsetX;
      this.imagePositions.clickY = event.offsetY;
      document.onmousemove = this.elementDragEdit;
      document.onmouseup = this.closeDragElement;
      this.wmSelected = true;
    },
    elementDrag(event) {
      var rect = this.$refs.watermarkContainer.getBoundingClientRect();

      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      this.imagePositions.clientX = x;
      this.imagePositions.clientY = y;

      event.preventDefault();
      // set the element's new position:
      this.$refs.watermarkPreview.style.top =
        y - this.imagePositions.clickY + "px";
      this.$refs.watermarkPreview.style.left =
        x - this.imagePositions.clickX + "px";

      this.imgTop = y - this.imagePositions.clickY;
      this.imgLeft = x - this.imagePositions.clickX;
    },
    elementDragEdit(event) {
      var rect = this.$refs.watermarkContainerEdit.getBoundingClientRect();

      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      this.imagePositions.clientX = x;
      this.imagePositions.clientY = y;

      event.preventDefault();
      // set the element's new position:
      this.$refs.watermarkPreviewEdit.style.top =
        y - this.imagePositions.clickY + "px";
      this.$refs.watermarkPreviewEdit.style.left =
        x - this.imagePositions.clickX + "px";

      this.imgTop = y - this.imagePositions.clickY;
      this.imgLeft = x - this.imagePositions.clickX;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.imagePositions.clickX = null;
      this.imagePositions.clickY = null;

      var rect = this.$refs.watermarkContainerEdit.getBoundingClientRect();

      console.log({
        imageWidth: this.fileInformation.width,
        imageHeight: this.fileInformation.height,
        containerHeight: rect.height,
        containerWidth: rect.width,
        posX: (this.imgLeft / rect.width) * 1920,
        posY: (this.imgTop / rect.height) * 1080,
        posY: this.imgTop,
        posX: this.imgLeft,
        scale: this.wmSf,
      });
    },
    async submitWatermarkProfile() {
      if (this.selectedFile == null) return;

      var rect = this.$refs.watermarkContainer.getBoundingClientRect();

      const data = new FormData();
      data.append("image", this.selectedFile);
      data.append("imageWidth", this.fileInformation.width);
      data.append("imageHeight", this.fileInformation.height);
      data.append("containerHeight", rect.height);
      data.append("containerWidth", rect.width);
      data.append("posY", (this.imgTop / rect.height) * 1080);
      data.append("posX", (this.imgLeft / rect.width) * 1920);
      data.append("name", this.profileName);
      data.append("opacity", this.opacity);
      data.append("imageScale", this.wmSf);

      let res = await axios.post(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.currentTeamId +
          "/settings/watermarks",
        data
      );
      return res.res;
    },
    async deleteWatermark(watermark, index) {
      try {
        await axios.delete(
          process.env.VUE_APP_API_URL +
            "/app/team/" +
            this.currentTeamId +
            "/settings/watermarks/" +
            watermark.id +
            "/delete"
        );
        this.watermarks.splice(index, 1);
      } catch (e) {
        alert("Error");
      }
    },
    editWatermark(watermark, index) {
      console.log(watermark);
      this.editData.image = watermark.publicImageUrl;
      this.modalStatus.editWatermarkOpen = !this.modalStatus.editWatermarkOpen;
      this.watermarkIndex = watermark.id;

      setTimeout(() => {
        var rect = this.$refs.watermarkContainerEdit.getBoundingClientRect();
        console.log(rect);

        this.fileInformation.width = watermark.image_width;
        this.fileInformation.height = watermark.image_height;

        this.opacity = watermark.opacity;
        this.wmSf = watermark.image_scale;

        console.log((watermark.pos_y / 1080) * rect.height);

        this.$refs.watermarkPreviewEdit.style.top =
          (watermark.pos_y / 1080) * rect.height + "px";
        this.$refs.watermarkPreviewEdit.style.left =
          (watermark.pos_x / 1920) * rect.width + "px";

        this.imgTop = (watermark.pos_y / 1080) * rect.height;
        this.imgLeft = (watermark.pos_x / 1920) * rect.width;

        this.editWatermarkOpenReady = true;
      }, 500);
    },
    async updateWatermark() {
      try {
        var rect = this.$refs.watermarkContainerEdit.getBoundingClientRect();

        await axios.put(
          process.env.VUE_APP_API_URL +
            "/app/team/" +
            this.currentTeamId +
            "/settings/watermarks/" +
            this.watermarkIndex +
            "/edit",
          {
            imageWidth: this.fileInformation.width,
            imageHeight: this.fileInformation.height,
            posY: (this.imgTop / rect.height) * 1080,
            posX: (this.imgLeft / rect.width) * 1920,
            name: this.name,
            opacity: this.opacity,
            imageScale: this.wmSf,
          }
        );
      } catch (e) {
        alert("Error");
        console.log(e);
      }
    },
  },
  watch: {
    wmSf(val) {
      this.fileComputedSize.width = (val / 100) * this.fileInformation.width;
    },
  },
};
</script>
