<template>
  <!-- Plan -->
  <section aria-labelledby="team_members">
    <div class="bg-white pt-6 space-y-6 shadow rounded-md overflow-hidden">
      <div class="px-4 sm:px-6 flex flex-row justify-between items-center">
        <h2
          id="team_members"
          class="text-lg leading-6 font-semibold text-gray-900"
        >
          Team Members
        </h2>
        <svg
          class="w-6 h-6 text-gray-500 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          v-on:click="addMemberModalOpen = !addMemberModalOpen"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div class="overflow-hidden border-t border-gray-200">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User Since
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th class="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-if="loadingTeamData">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <ImageLoader
                            :loading="true"
                            type="circle"
                            width="10"
                            height="10"
                          ></ImageLoader>
                        </div>
                        <div class="ml-4 space-y-1">
                          <div
                            class="text-sm leading-5 font-medium text-gray-900 flex flex-row content-center"
                          >
                            <TextLoader :loading="true" width="36"></TextLoader>
                          </div>
                          <TextLoader :loading="true" width="20"></TextLoader>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        <TextLoader :loading="true"></TextLoader>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <TagLoader :loading="true">
                        <div
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        >
                          Active
                        </div>
                      </TagLoader>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                    >
                      <TextLoader :loading="true"></TextLoader>
                    </td>
                  </tr>
                  <tr v-for="user in users" v-bind:key="'users-' + user.id">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div
                            class="h-10 w-10 rounded-full bg-gray-700 text-white flex items-center justify-center font-semibold text-lg"
                          >
                            TY
                          </div>
                        </div>
                        <div class="ml-4 space-y-1">
                          <div
                            class="text-sm leading-5 font-medium text-gray-900 flex flex-row content-center"
                          >
                            {{ user.name }}
                            <span
                              class="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800"
                              v-if="user.id == currentUser.id"
                            >
                              you
                            </span>
                          </div>
                          <div class="text-sm leading-5 text-gray-500">
                            {{ user.email }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ user.created_at | displayDateTime }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <span
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                      >
                        Active
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                    >
                      {{ user.user_role.role }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                    >
                      <a href="#" class="text-blue-600 hover:text-blue-900"
                        >Edit</a
                      >
                    </td>
                  </tr>
                  <tr
                    v-for="user in invitedUsers"
                    v-bind:key="'invitedUsers-' + user.id"
                  >
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div
                            class="h-10 w-10 rounded-full bg-gray-700 text-white flex items-center justify-center font-semibold text-sm"
                          >
                            <i class="fa-solid fa-user-clock fa-fw"></i>
                          </div>
                        </div>
                        <div class="ml-4 space-y-1">
                          <div
                            class="text-sm leading-5 font-medium text-gray-900 flex flex-row content-center"
                          >
                            {{ user.email }}
                          </div>
                          <div class="text-sm leading-5 text-gray-500">
                            Invited by {{ user.invitingUser }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ user.created_at | displayDateTime }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <span
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                      >
                        Pending
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                    >
                      Member
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                    >
                      <a href="#" class="text-blue-600 hover:text-blue-900"
                        >Edit</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="Add a Member"
      :open="addMemberModalOpen"
      @close="addMemberModalOpen = !addMemberModalOpen"
      :showAcceptButton="false"
    >
      <p class="text-gray-600">
        You can quickly add a member to your team by entering their email below.
        Once you submit an email address, the user will be emailed with a link
        to sign up for an account or a link to join the team depending on their
        account status.
      </p>

      <div class="flex flex-col space-y-4 mt-8">
        <div class="flex flex-col space-y-1">
          <label class="text-gray-800 font-semibold" for="addEmail"
            >Email Address</label
          >
          <input
            type="email"
            id="addEmail"
            name="addEmail"
            class="w-full h-10 px-2 py-1.5 bg-white rounded-lg border-gray-200"
            placeholder="jdoe@mail.com"
            v-model="emailAddress"
          />
        </div>

        <button
          class="w-full px-4 py-1.5 bg-gray-700 text-white rounded-lg hover:bg-gray-500 focus:outline-none"
          v-on:click="inviteUser"
        >
          Invite Member
        </button>
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

import TextLoader from "@/screens/App/General/Loaders/TextLoader";
import ImageLoader from "@/screens/App/General/Loaders/ImageLoader";
import TagLoader from "@/screens/App/General/Loaders/TagLoader";

import Modal from "@/components/Modal";

export default {
  name: "TeamMemberSettings",
  data() {
    return {
      showUploadPopover: false,
      addMemberModalOpen: false,
      emailAddress: null,
    };
  },
  components: { TextLoader, ImageLoader, TagLoader, Modal },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      invitedUsers: (state) => state.invitedUsers,
      loadingTeamData: (state) => state.loadingTeamData,
    }),
  },
  methods: {
    async inviteUser() {
      try {
        let res = await this.$http.post(
          process.env.VUE_APP_API_URL +
            "/app/team/" +
            this.currentTeamId +
            "/settings/users/invite",
          {
            email: this.emailAddress,
          }
        );
        alert("User added successfully.");
        this.addMemberModalOpen = false;
        this.emailAddress = null;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
