<template>
  <!-- Plan -->
  <section aria-labelledby="team_members">
    <div class="bg-white pt-6 space-y-6 shadow rounded-md overflow-hidden">
      <div class="px-4 sm:px-6 flex flex-row justify-between items-center">
        <h2
          id="team_members"
          class="text-lg leading-6 font-semibold text-gray-900"
        >
          Social Accounts
        </h2>
        <svg
          class="w-6 h-6 text-gray-500 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          v-on:click="
            modalStatus.generalSocialModalOpen = !modalStatus.generalSocialModalOpen
          "
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
      <div class="w-full space-y-4" v-if="socialAccounts.length == 0">
        <p>
          This team currently does not have any social connections. Add one to
          begin using our platform!
        </p>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden border-t border-gray-200"
              v-if="socialAccounts.length > 0"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span class="block ml-12"> Name</span>
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Connected Since
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Connected By
                    </th>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th class="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="account in socialAccounts" v-bind:key="account.id">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="w-8 flex justify-center">
                          <img
                            class="w-4 h-4"
                            src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
                            v-if="account.type == 'facebook'"
                          />
                          <img
                            class="w-auto h-4"
                            src="@/assets/3rd-party/yt-icon.png"
                            v-if="account.type == 'youtube'"
                          />
                          <img
                            class="w-auto h-4"
                            src="@/assets/3rd-party/2021-twitter-logo-blue.png"
                            v-if="account.type == 'twitter'"
                          />
                        </div>
                        <div
                          class="text-sm leading-5 font-medium text-gray-900 flex flex-row content-center ml-4"
                        >
                          {{ account.display_name }}
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ account.created_at | displayDateTime }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ account.user.name }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <span
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                        v-if="account.isExpired"
                      >
                        Expired
                      </span>
                      <span
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        v-if="!account.isExpired"
                      >
                        Active
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                    >
                      <a href="#" class="text-blue-600 hover:text-blue-900"
                        >Edit</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="Connect a Social Platform"
      :open="modalStatus.generalSocialModalOpen"
      @close="
        modalStatus.generalSocialModalOpen = !modalStatus.generalSocialModalOpen
      "
      :showAcceptButton="false"
    >
      <transition>
        <div id="selection" v-if="selection == 1">
          <h1 v-if="availableSocialPlatforms.length == 0">
            No social platforms are currently available.
          </h1>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <button
              class="h-32 flex flex-col bg-gray-800 rounded-md p-4 w-full text-center focus:outline-none text-white hover:bg-gray-700 transition-all relative"
              v-for="platform in availableSocialPlatforms"
              v-bind:key="platform.platform"
              v-on:click="clickedPlatform(platform)"
            >
              <div class="flex-1 w-full flex items-center content-center ">
                <img
                  :class="platformImages[platform.platform].class"
                  :src="platformImages[platform.platform].src"
                />
              </div>
              <div class="mt-2 w-full">{{ platform.displayName }}</div>
              <div
                class="absolute top-0 right-0 mt-2 mr-2"
                v-if="platform.devOnly"
              >
                <span
                  class="inline-block px-2 py-1 bg-yellow-400 rounded-full text-xs uppercase font-semibold"
                  >Dev</span
                >
              </div>
            </button>
          </div>
        </div>
        <div class="flex flex-col space-y-4" id="next" v-if="selection == 2">
          <div
            class="w-full h-64 bg-gray-800 rounded-lg flex flex-col items-center content-center justify-center space-y-10 text-center"
          >
            <div class="semipolar-spinner">
              <div class="spinner-ring"></div>
              <div class="spinner-ring"></div>
              <div class="spinner-ring"></div>
              <div class="spinner-ring"></div>
              <div class="spinner-ring"></div>
            </div>
            <span class="text-gray-300"
              >Continue in the pop up window. <br />
              Once complete, wait a few moments.</span
            >
          </div>
          <button
            class="w-full bg-red-500 focus:outline-none px-4 py-2 text-white rounded-lg"
            v-on:click="cancelSocialSelection"
          >
            Cancel
          </button>
        </div>
        <div class="flex flex-col space-y-4" id="next" v-if="selection == 3">
          <div
            class="w-full h-64 rounded-lg flex flex-col items-center content-center justify-center space-y-10 text-center"
          >
            <i class="fa-duotone fa-circle-check text-8xl text-green-500"></i>
            <span class="text-gray-800"
              >Social platform successfully added.</span
            >
          </div>
          <button
            class="w-full bg-gray-800 focus:outline-none px-4 py-2 text-white rounded-lg"
            v-on:click="cancelSocialSelection"
          >
            Close
          </button>
        </div>

        <div class="flex flex-col space-y-4" id="next" v-if="selection == 4">
          <div class="flex items-center content-center">
            <img
              class="cursor-pointer"
              :src="platformImages[selectedPlatformId].signInSrc"
              v-on:click="clickedPlatform(selectedPlatformId)"
            />
          </div>
          <h2 class="font-semibold mt-4">About</h2>
          <p>
            With over 1 billion users, YouTube is the biggest video viewing
            platform in the world. It remains the #1 platform for vloggers,
            gamers, and the like.
          </p>

          <p class="mt-4">
            By connecting your Google and YouTube account, you agree to
            <a href="#" class="text-blue-700">YouTube Terms of Service</a> and
            <a href="#" class="text-blue-700">Google's Privacy Policy</a>.
          </p>

          <p>
            <a
              href="https://myaccount.google.com/permissions"
              class="text-blue-700"
              target="_blank"
              >Revoke access</a
            >
          </p>
        </div>
        <div class="flex flex-col space-y-4" id="next" v-if="selection == 5">
          Select the Facebook pages that you would like to include.
          <div
            class="h-40 flex items-center content-center justify-center text-2xl text-gray-600 "
            v-if="loadingEntities"
          >
            <i class="fa fa-circle-notch fa-spin"></i>
          </div>

          <fieldset v-if="!loadingEntities">
            <div class="rounded-md bg-yellow-50 p-4 my-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/exclamation -->
                  <svg
                    class="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3 text-sm text-yellow-700">
                  <p>
                    Personal Facebook accounts are not currently supported but
                    will be in the near future.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              <div
                class="relative flex items-center content-center py-4 group"
                v-for="entity in availableEntities"
                v-bind:key="entity.id"
              >
                <div
                  class="min-w-0 flex-1 flex flex-row items-center content-center text-sm space-x-3"
                >
                  <div
                    class="w-8 h-8 bg-gray-300 rounded-full flex items-center content-center justify-center text-xs"
                  >
                    <i class="fas fa-city"></i>
                  </div>
                  <div class="flex flex-col">
                    <label
                      :for="'se-' + entity.id"
                      class="font-semibold text-gray-800 select-none"
                      >{{ entity.name }}</label
                    >
                    <span class="text-sm text-gray-600">{{
                      entity.category
                    }}</span>
                  </div>
                </div>
                <div class="ml-3 flex items-center h-5">
                  <input
                    :id="'se-' + entity.id"
                    :name="'se-' + entity.id"
                    type="checkbox"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    v-model="entity.selected"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <button
            class="px-4 py-1.5 bg-gray-700 text-white rounded-lg hover:bg-gray-500 focus:outline-none"
            v-on:click="attachFacebookEntries"
          >
            Attach Facebook Pages
          </button>
        </div>
      </transition>
    </Modal>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Modal from "@/components/Modal";

let platformImages = {
  facebook: {
    src:
      "https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512",
    class: "w-12 h-12 mx-auto",
  },
  youtube: {
    src: require("@/assets/3rd-party/yt_logo_rgb_dark.png"),
    signInSrc: require("@/assets/3rd-party/btn_google_signin_dark_normal_web.png"),
    class: "h-8 mx-auto",
  },
  twitter: {
    src: require("@/assets/3rd-party/2021-twitter-logo-blue.png"),
    class: "h-10 mx-auto",
  },
  linkedin: {
    src:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/768px-LinkedIn_logo_initials.png",
    class: "h-10 mx-auto",
  },
  cloudsprouter: {
    src: require("@/assets/icon-cloudsprouter-logo.png"),
    class: "h-10 mx-auto",
  },
};

export default {
  name: "SocialAccountSettings",
  data() {
    return {
      currentTs: null,
      modalStatus: {
        generalSocialModalOpen: false,
      },
      availableSocialPlatforms: [],
      platformImages: platformImages,
      selection: 1,
      selectedPlatformId: null,
      checkWindowClosedInterval: null,
      openedWindow: null,
      loadingEntities: false,
      secondaryAccountRequest: false,
    };
  },
  mounted() {
    this.currentTs = new Date();
    this.getAvailableSocialPlatforms();

    var self = this;
    window.onmessage = function(e) {
      let data = e.data;
      if (data.source !== undefined && data.status !== undefined) {
        console.log(data);
        if (data.source == "oauth-popup" && data.status == "success") {
          if (!self.secondaryAccountRequest) {
            self.selection = 3;
            self.openedWindow = null;
            self.updateTeamContentSection({ type: "socialAccounts" });
          } else {
            self.fetchAvailableFacebookEntities();
            self.selection = 5;
          }
        }
      }
    };
  },
  components: { Modal },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      socialAccounts: (state) => state.socialAccounts,
    }),
  },
  methods: {
    ...mapActions("team", ["updateTeamContentSection"]),
    async getAvailableSocialPlatforms() {
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL + "/app/available-connections"
      );
      this.availableSocialPlatforms = res.data;
      // "@/assets/3rd-party/2021-twitter-logo-blue.png"
    },
    async getCorrectImageSource(src) {
      let res = await import(src);
      // console.log(res.default);
      return res.default;
    },

    // TODO: Clean this up
    clickedPlatform(platform) {
      // this.fetchAvailableFacebookEntities();

      this.selectedPlatformId = platform.platform;
      this.secondaryAccountRequest = platform.secondaryAccountRequest;

      if (
        platform.requiresConsentScreen !== undefined &&
        platform.requiresConsentScreen &&
        this.selection !== 4
      ) {
        this.selection = 4;
        return;
      }

      // TODO: weird thing for youtube, need to improve this
      if (this.selection == 4) {
        var search = this.availableSocialPlatforms.find((val) => {
          return val.platform == platform;
        });
        platform = search;
      }

      this.selection = 2;

      let connectionUrl = platform.connectionUrl;
      if (platform.includeTeamInUrl) {
        connectionUrl = connectionUrl + "?teamId=" + this.currentTeamId;
      }

      this.openedWindow = openOauthWindow(connectionUrl);
    },
    cancelSocialSelection() {
      this.selection = 1;
      if (this.openedWindow) {
        this.openedWindow.close();
        this.openedWindow = null;
        this.selectedPlatformId = null;
      }
    },
    async fetchAvailableFacebookEntities() {
      this.loadingEntities = true;
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL + "/app/connections/fb-accounts"
      );
      this.availableEntities = res.data;
      this.loadingEntities = false;
    },

    async attachFacebookEntries() {
      let selectedEntries = [];
      for (const entry of this.availableEntities) {
        if (entry.selected) {
          selectedEntries.push(entry.id);
        }
      }

      try {
        await this.$http.post(
          process.env.VUE_APP_API_URL +
            "/app/team/" +
            this.currentTeamId +
            "/entities",
          {
            ids: selectedEntries,
          }
        );
        this.updateTeamContentSection({ type: "socialAccounts" });
        this.selection = 3;
      } catch (e) {
        // proper error handling needed
        alert("Experienced an error, try again.");
      }
    },

    async refreshAttachedEntries() {
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.currentTeamId +
          "/entities"
      );
      this.establishedEntities = res.data;
    },
  },
  watch: {
    "modalStatus.generalSocialModalOpen": function(val) {
      this.cancelSocialSelection();
    },
  },
};

/**
 * @param  {Object} options
 * @return {Window}
 */
function openOauthWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }
  options = { url, title, width: 600, height: 720, ...options };
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;
  options.self = "_self";
  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;
  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");
  const newWindow = window.open(url, title, optionsStr);
  if (!newWindow) {
    alert(
      "Please unblock popups in your browser settings to connect social accounts."
    );
  } else if (window.focus) {
    newWindow.focus();
  }
  return newWindow;
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* fix wobble on spinning circle-o-notch */
.fa-circle-notch {
  transform-origin: 50% 48.95%;
}

.semipolar-spinner,
.semipolar-spinner * {
  box-sizing: border-box;
}

.semipolar-spinner {
  height: 65px;
  width: 65px;
  position: relative;
}

.semipolar-spinner .spinner-ring {
  border-radius: 50%;
  position: absolute;
  border: calc(65px * 0.05) solid transparent;
  border-top-color: rgba(37, 99, 235, 1);
  border-left-color: rgba(37, 99, 235, 1);
  animation: semipolar-spinner-animation 2s infinite;
}

.semipolar-spinner .spinner-ring:nth-child(1) {
  height: calc(65px - 65px * 0.2 * 0);
  width: calc(65px - 65px * 0.2 * 0);
  top: calc(65px * 0.1 * 0);
  left: calc(65px * 0.1 * 0);
  animation-delay: calc(2000ms * 0.1 * 4);
  z-index: 5;
}

.semipolar-spinner .spinner-ring:nth-child(2) {
  height: calc(65px - 65px * 0.2 * 1);
  width: calc(65px - 65px * 0.2 * 1);
  top: calc(65px * 0.1 * 1);
  left: calc(65px * 0.1 * 1);
  animation-delay: calc(2000ms * 0.1 * 3);
  z-index: 4;
}

.semipolar-spinner .spinner-ring:nth-child(3) {
  height: calc(65px - 65px * 0.2 * 2);
  width: calc(65px - 65px * 0.2 * 2);
  top: calc(65px * 0.1 * 2);
  left: calc(65px * 0.1 * 2);
  animation-delay: calc(2000ms * 0.1 * 2);
  z-index: 3;
}

.semipolar-spinner .spinner-ring:nth-child(4) {
  height: calc(65px - 65px * 0.2 * 3);
  width: calc(65px - 65px * 0.2 * 3);
  top: calc(65px * 0.1 * 3);
  left: calc(65px * 0.1 * 3);
  animation-delay: calc(2000ms * 0.1 * 1);
  z-index: 2;
}

.semipolar-spinner .spinner-ring:nth-child(5) {
  height: calc(65px - 65px * 0.2 * 4);
  width: calc(65px - 65px * 0.2 * 4);
  top: calc(65px * 0.1 * 4);
  left: calc(65px * 0.1 * 4);
  animation-delay: calc(2000ms * 0.1 * 0);
  z-index: 1;
}

@keyframes semipolar-spinner-animation {
  50% {
    transform: rotate(360deg) scale(0.7);
  }
}
</style>
