<template>
  <div :class="{ 'animate-pulse': loading }">
    <div
      :class="['rounded-full px-2', computedClass, widthClass, heightClass]"
      v-if="loading"
    ></div>
    <slot v-if="!loading"></slot>
  </div>
</template>

<script>
export default {
  name: "TagLoader",
  props: ["loading", "color", "width", "height"],
  computed: {
    computedClass: function() {
      if (this.color) {
        return "bg-" + this.color;
      }
      return "bg-gray-200";
    },
    widthClass: function() {
      if (this.width == undefined) return "w-14";
      return `w-${this.width}`;
    },
    heightClass: function() {
      if (this.height == undefined) return "h-6";
      return `h-${this.height}`;
    },
  },
};
</script>
